import React from 'react'

import Layout from '../../../components/layout'
import Container from '../../../components/container'
import ResidenceDetails from '../../../components/residence-details'

import Process5 from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/MAR_05.jpg'
import Process6 from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/MAR_06.jpg'
import Process7 from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/MAR_07.jpg'
import Process10 from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/MAR_10.jpg'
import Process11 from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/MAR_11.jpg'
import Process5s from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_05.jpg'
import Process6s from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_06.jpg'
import Process7s from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_07.jpg'
import Process10s from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_10.jpg'
import Process11s from '../../../images/work/16_PB_MARINA_OLEADA/PROCESS/450x280/MAR-SM_11.jpg'

const Residence03 = () => (
  <Layout>
    <Container>
      <ResidenceDetails
        residenceTitle="PEBBLE BEACH RESIDENCE"
        residenceLocation="PEBBLE BEACH, CALIFORNIA"
        infoList={['Remodel of existing 4,230 SF single family residence. Phase 1 - interior remodel with new upper level dormers. Phase 2 - 2-story master bedroom and theater (play room) addition with upper level terrace/ observation deck.', 'Phase 1 Design - 2017', 'Phase 1 Construction - 2017-2018', 'Owner - Builder', 'Structural Engineer: Structures Engineering, Inc']}
        testimonialList={[]}
        photos={[]}
        photosSmall={[]}
        processPhotos={[Process5,Process6,Process7,Process10,Process11]}
        processPhotosSmall={[Process5s,Process6s,Process7s,Process10s,Process11s]}
        />
    </Container>
  </Layout>
)

export default Residence03;
